import React, { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { NURSE_ROUTES } from '../nurse.routes';
import { Icon, Page, URL_PARAMETERS } from '@when-fertility/shared/domain/common';
import { QuestionnaireSummary } from '@when-fertility/shared/domain/questionnaire/questionnaire-summary.component';
import { QuestionnaireProvider } from '@when-fertility/shared/domain/questionnaire/questionnaire.provider';
import { useQuestionnairePageGetTestKitByIdQuery } from '@when-fertility/shared/gql/graphql';

export const QuestionnairePage = () => {
  const [searchParams] = useSearchParams();
  const testKitId = useMemo(() => searchParams.get(URL_PARAMETERS.testKitId), [searchParams]);
  const { data: testKitData } = useQuestionnairePageGetTestKitByIdQuery({
    variables: { input: { id: testKitId || '' } },
    skip: !testKitId,
    fetchPolicy: 'cache-and-network',
  });

  const navigate = useNavigate();

  if (!testKitId) {
    return <div className="text-2xl mt-8 text-center">No test kit found</div>;
  }

  return (
    <Page className="pt-20 md:pt-36">
      <div className="text-2xl flex items-center">
        <div className="hover:cursor-pointer" onClick={() => navigate(`${NURSE_ROUTES.patientProfile}?id=${testKitData?.findByID.userId}`)}>
          <Icon icon="arrow-left" height={23} className="mr-4" />
        </div>
        <span>Test kit Accession Number: {testKitData?.findByID.accessionNumber}</span>
      </div>
      <div className="flex flex-col md:flex-row gap-6 mt-6">
        <div className="w-full">
          <QuestionnaireProvider testKitId={testKitId}>
            <QuestionnaireSummary readOnly={true} />
          </QuestionnaireProvider>
        </div>
      </div>
    </Page>
  );
};

QuestionnairePage.fragments = {
  testKit: gql`
    fragment QuestionnairePageTestKit on TestKit {
      id
      userId
      accessionNumber
    }
  `,
};

QuestionnairePage.query = {
  testKitById: gql`
    ${QuestionnairePage.fragments.testKit}

    query QuestionnairePageGetTestKitById($input: ByIdInput) {
      findByID(input: $input) {
        ...QuestionnairePageTestKit
      }
    }
  `,
};
