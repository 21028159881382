import React, { useMemo, useState } from 'react';
import { AUTH_ROUTES, useAuth } from 'domain/auth';
import { Link, useLocation } from 'react-router-dom';
import { Icon, HeaderNavLink } from '@when-fertility/shared/domain/common';
import { NURSE_ROUTES } from '../nurse.routes';
import cn from 'classnames';

const TRANSPARENT_BACKGROUND_ROUTES = [NURSE_ROUTES.dashboard];

// TODO: consider splitting responsive features into shared component

export const NurseNav = () => {
  const { isAuthenticated } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const background = useMemo(() => {
    if (TRANSPARENT_BACKGROUND_ROUTES.includes(pathname)) {
      return 'transparent';
    }
    return 'solid';
  }, [pathname]);

  return (
    <div
      className={cn('sticky top-0 z-50', {
        'bg-silver-100': background === 'solid',
        'bg-transparent': background === 'transparent',
      })}
    >
      <div
        className={cn('flex justify-between w-full items-center px-4 h-nav-mobile md:h-nav-desktop border-b', {
          'bg-silver-40 border-b-charcoal-60': background === 'solid',
          'bg-transparent border-b-white': background === 'transparent',
        })}
      >
        <div className="flex items-center">
          <Link to={NURSE_ROUTES.dashboard} onClick={() => setIsMenuOpen(false)}>
            <div className="hidden md:block">
              <Icon icon="when" height={30} width={100} />
            </div>
            <div
              className={cn('block text-3xl md:hidden', {
                'text-white': background === 'transparent',
              })}
            >
              WHEN
            </div>
          </Link>
          <div onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <Icon icon={isMenuOpen ? 'cross' : 'hamburger'} className="sm:hidden ml-2" height={20} width={20} />
          </div>
        </div>
        <div>
          {isAuthenticated && (
            <div className="flex h-10">
              <div
                className={cn('hidden sm:flex items-center rounded-full px-6 leading-5 mr-3', {
                  'text-white': background === 'transparent',
                })}
              >
                <HeaderNavLink to={NURSE_ROUTES.dashboard}>Dashboard</HeaderNavLink>
              </div>
              <div className="flex items-center justify-center bg-white rounded-full px-6 mr-3">
                <HeaderNavLink to={AUTH_ROUTES.logout} onClick={() => setIsMenuOpen(false)}>
                  Log out
                </HeaderNavLink>
              </div>
            </div>
          )}
        </div>
      </div>
      {isMenuOpen && (
        <div className="sm:hidden bg-lemon-100 flex flex-col p-6 gap-2 drop-shadow-md" onClick={() => setIsMenuOpen(false)}>
          <HeaderNavLink to={NURSE_ROUTES.dashboard}>Dashboard</HeaderNavLink>
          <HeaderNavLink to={NURSE_ROUTES.patients}>Patients</HeaderNavLink>
          <HeaderNavLink to={AUTH_ROUTES.logout}>Log out</HeaderNavLink>
        </div>
      )}
    </div>
  );
};
