import { BlobIcon, Card, Icon, Page, Button } from '@when-fertility/shared/domain/common';
import { PatientsListComponent } from '../patients/components/patients-list.component';
import { SearchPatients, FilterState } from '../patients/components/search-patients.component';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const NurseDashboardPage = () => {
  const [search, setSearch] = useState('');
  const [filter, setFilterState] = useState<FilterState>('ALL');

  const searchHandler = (e: any) => {
    setSearch(e.target.value);
  };

  const activeVariant = (option: FilterState) => (filter === option ? 'primary' : 'secondary');

  const navigate = useNavigate();
  return (
    <Page
      title="Dashboard"
      headerClassName="bg-wave-result-mobile sm:bg-wave-result-standard 2xl:bg-wave-result-wide bg-bottom bg-cover bg-no-repeat h-40 sm:h-64 text-silver-60"
    >
      <div className="flex justify-between items-center align-middle mb-4">
        <SearchPatients searchHandler={searchHandler} search={search} />
        <Button className="text-nowrap ml-2" variant={activeVariant('ALL')} onClick={() => setFilterState('ALL')}>
          All
        </Button>
        <Button className="text-nowrap ml-2" variant={activeVariant('AWAITING_RESULTS')} onClick={() => setFilterState('AWAITING_RESULTS')}>
          Awaiting results
        </Button>
        <Button className="text-nowrap ml-2" variant={activeVariant('READY_TO_RELEASE')} onClick={() => setFilterState('READY_TO_RELEASE')}>
          Ready to release
        </Button>
      </div>
      <PatientsListComponent className="rounded-lg overflow-hidden" filter={filter} search={search} />
    </Page>
  );
};
