import { Button, Icon, Modal, ArrayElement, HeaderNavLink } from '@when-fertility/shared/domain/common';
import { GqlPatientProfilePageUserFragmentFragment, usePatientResultsButtonsReleaseResultsMutation } from '@when-fertility/shared/gql/graphql';
import { testKitService } from '@when-fertility/nurse/domain/test-kit';
import gql from 'graphql-tag';
import React, { useMemo, useState } from 'react';

type Props = {
  testKit: ArrayElement<GqlPatientProfilePageUserFragmentFragment['testKits']>;
  userId?: string;
};

export const PatientResultsButtons = ({ testKit, userId }: Props) => {
  const areResultsReceived = useMemo(() => testKit.resultsStatus !== 'NOT_RECEIVED', [testKit.resultsStatus]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [releaseResults, { loading, error }] = usePatientResultsButtonsReleaseResultsMutation();
  const hasGeneratedReport = useMemo(() => testKit && testKit.results?.find((result) => result.report), [testKit]);
  return (
    <>
      {!areResultsReceived && <div className="italic text-xs mt-3">Results not yet received</div>}
      {/*{areResultsReceived && (*/}
      {/*  <Button variant="secondary" className="text-silver-60 border-silver-60 mr-3 mt-3" size="small" isDisabled>*/}
      {/*    View report*/}
      {/*  </Button>*/}
      {/*)}*/}
      {testKit.resultsStatus === 'RECEIVED_NOT_RELEASED' && hasGeneratedReport && (
        <Button variant="primary" size="small" onClick={() => setIsModalOpen(true)}>
          <div className="flex items-center justify-center">
            Send To Patient <Icon icon="mail" className="ml-1" />
          </div>
        </Button>
      )}
      {testKit.resultsStatus === 'RECEIVED_NOT_RELEASED' && !hasGeneratedReport && (
        <div className="text-xs mt-3 text-center">Report not yet generated (questionnaire may be incomplete)</div>
      )}
      {testKit.resultsStatus === 'RECEIVED_RELEASED' && (
        <div className="text-xs mt-3">
          <Icon icon="check" /> Results released to patient
        </div>
      )}
      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title="Confirm">
        <div className="text-charcoal-100">
          <div>Would you like to release these results to the patient? This cannot be undone.</div>
          <div className="flex justify-end mt-6">
            <Button variant="secondary" className="mr-3" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button
              isDisabled={loading}
              onClick={() => releaseResults({ variables: { input: { id: testKit.id } }, onCompleted: () => setIsModalOpen(false) })}
            >
              Confirm
            </Button>
            {error && <div className="text-red-500">Something went wrong releasing results</div>}
          </div>
        </div>
      </Modal>
    </>
  );
};

PatientResultsButtons.mutation = gql`
  mutation PatientResultsButtonsReleaseResults($input: ReleaseTestKitResultsInput!) {
    releaseTestKitResults(input: $input) {
      id
      resultsStatus
    }
  }
`;
