import React from 'react';
import cn from 'classnames';
import { Input } from '@when-fertility/shared/domain/common';

type Props = {
  searchHandler: () => void;
  search: string;
};

export const SearchPatients = ({ searchHandler, search }: Props) => {
  return <Input className={cn({ 'flex items-center': true })} placeholder="Search patients" value={search} onChange={searchHandler} />;
};
