import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { NoteSection } from '.';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Card, Icon, Page, PopUpModal, TextArea, URL_PARAMETERS } from '@when-fertility/shared/domain/common';
import {
  useAppNotePageGetPatientQuery,
  useRegenerateReportMutation,
  useFinishApptNotesMutation,
  useUpdateApptNotesMutation,
} from '@when-fertility/shared/gql/graphql';
import { gql } from '@apollo/client';
import { QuestionnaireProvider } from 'domain/user';
import { NURSE_ROUTES } from '..';
import { testKitService } from 'domain/test-kit';
import { NoteAgenda } from './components/note-agenda.component';
import { NoteAudit } from './components/note-audit.component';
import { NoteActions } from './components/note-actions.component';
import { QuestionnaireSummary } from '@when-fertility/shared/domain/questionnaire/questionnaire-summary.component';

export const ApptNotePage = () => {
  const [searchParams] = useSearchParams();
  const userId = useMemo(() => searchParams.get(URL_PARAMETERS.userId), [searchParams]);
  const testKitId = useMemo(() => searchParams.get(URL_PARAMETERS.testKitId), [searchParams]);
  const navigate = useNavigate();

  const { data } = useAppNotePageGetPatientQuery({
    variables: { input: { id: userId || '' } },
    skip: !userId,
    fetchPolicy: 'cache-and-network',
  });

  const [apptNotes, setApptNotes] = useState({});
  useEffect(() => {
    checkFormFilled();
    if (isFormFilled && !loading) {
      setFinishSubmitDisabled(false);
    }
  });
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [finishSubmitDisabled, setFinishSubmitDisabled] = useState(true);

  const testKit = useMemo(() => {
    const testKit = testKitService.getTestKitById(data?.patientById.testKits, testKitId);
    setApptNotes(testKit?.apptNotes);
    return testKit;
  }, [data, testKitId, setApptNotes]);

  function handleNoteChange() {
    const type = event.target.type;
    const keys = event.target.id.split('-');
    if (type === 'checkbox') {
      setApptNotes({
        ...apptNotes,
        [keys[0]]: {
          ...apptNotes[keys[0]],
          [keys[1]]: event.target.checked,
        },
      });
    } else {
      setApptNotes({
        ...apptNotes,
        [keys[0]]: {
          ...apptNotes[keys[0]],
          [keys[1]]: event.target.value,
        },
      });
    }
  }

  const [handleSaveForLater] = useUpdateApptNotesMutation({
    variables: {
      input: { id: testKitId || '', userId: userId || '', apptNotes },
    },
    onCompleted: async () => {
      navigate(`${NURSE_ROUTES.patientProfile}?id=${userId}`);
    },
  });

  const [handleFinish, { loading }] = useFinishApptNotesMutation({
    variables: {
      input: { id: testKitId || '', userId: userId || '', apptNotes },
    },
    onCompleted: async () => {
      navigate(`${NURSE_ROUTES.patientProfile}?id=${userId}`);
    },
  });

  const checkFormFilled = () => {
    if (!apptNotes) {
      return;
    }

    const keys = Object.keys(apptNotes);
    const isFilledResult: boolean[] = [];
    keys.forEach((key) => {
      if (key === '__typename' || apptNotes[key] === null) {
        return;
      }
      const contentKeys = Object.keys(apptNotes[key]);
      contentKeys.forEach((contentKey) => {
        if (contentKey === '__typename') {
          return;
        }
        if (contentKey !== 'notes' && (apptNotes[key][contentKey] === null || apptNotes[key][contentKey] === '')) {
          isFilledResult.push(false);
        } else {
          isFilledResult.push(true);
        }
      });
    });
    setIsFormFilled(!isFilledResult.includes(false));
  };

  const finishAndSave = async () => {
    setFinishSubmitDisabled(true);
    await handleFinish();
  };

  const [regenerateReason, setRegenerateReason] = useState('');

  const [handleModalConfirmation, { loading: regenerateLoading, error: regenerateError }] = useRegenerateReportMutation({
    variables: { input: { id: testKitId, comment: regenerateReason } },
    onCompleted: async () => {
      setIsModalOpen(false);
    },
  });

  const handleModalConfirmed = async () => {
    await handleModalConfirmation();
  };

  const handleReasonChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRegenerateReason(event.target.value);
  };

  return (
    <QuestionnaireProvider userId={userId}>
      <Page className="pt-20 md:pt-36">
        <div className="text-2xl flex items-center">
          <div className="hover:cursor-pointer" onClick={() => navigate(`${NURSE_ROUTES.patientProfile}?id=${userId}`)}>
            <Icon icon="arrow-left" height={23} className="mr-4" />
          </div>
          <span>{data?.patientById.user.firstName}&apos;s Patient Profile</span>
        </div>
        <div className="flex flex-col md:flex-row gap-6 mt-6">
          <div className="w-full md:w-3/4">
            <NoteAgenda user={data?.patientById.user} apptNotes={apptNotes} handleNoteChange={handleNoteChange} />
            <NoteAudit user={data?.patientById.user} apptNotes={apptNotes} handleNoteChange={handleNoteChange} />
            <NoteSection headerText="Answer">
              <div className="md:flex p-6 border bg-clip-border rounded-xl border-silver-100 bg-silver-40 last:border-none mt-4">
                <div className="flex w-full flex-row-reverse">
                  <input
                    id="answer-messageDelivered"
                    type="checkbox"
                    checked={apptNotes?.answer?.messageDelivered || ''}
                    className={cn('border-silver-100 h-6 w-6 mr-4')}
                    onChange={handleNoteChange}
                  />
                  <label htmlFor="answer-messageDelivered" className="mr-2">
                    Message delivered: egg count does not predict your chances of becoming pregnant
                  </label>
                </div>
              </div>
              <div className="mt-8" />
              <TextArea id="answer-notes" value={apptNotes?.answer?.notes || ''} onChange={handleNoteChange} />
            </NoteSection>
            <NoteActions apptNotes={apptNotes} handleNoteChange={handleNoteChange} />
            <Card className="mt-6 rounded-b-none border-b-0 last:border-none">
              <div>
                <p className="text-xl text-charcoal-100">Nurse notes</p>
              </div>
              <div className="md:flex flex-col p-6 border bg-clip-border rounded-xl border-silver-100 bg-silver-40 last:border-none mt-4">
                <div className="w-full mb-4 mt-2 flex flex-row-reverse">
                  <select
                    id="nurse-concernAboutClient"
                    value={apptNotes?.nurse?.concernAboutClient || ''}
                    className={cn('bg-white border-silver-100 border rounded h-6 w-16 mt-4 text-center')}
                    onChange={handleNoteChange}
                  >
                    <option value=""></option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  <label htmlFor="nurse-concernAboutClient" className="mr-2 mt-4 text-right">
                    Do you have any concerns for the mental or physical welfare of the client? If yes, detail what you said to them below
                  </label>
                </div>
                <div className="w-full mb-4 mt-2 flex flex-row-reverse">
                  <select
                    id="nurse-technicalIssuesDuringAppt"
                    value={apptNotes?.nurse?.technicalIssuesDuringAppt || ''}
                    className={cn('bg-white border-silver-100 border rounded h-6 w-16 mt-4 text-center')}
                    onChange={handleNoteChange}
                  >
                    <option value=""></option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  <label htmlFor="nurse-technicalIssuesDuringAppt" className="mr-2 mt-4 text-right">
                    Were there any technical issues during the appointment? If yes, detail below
                  </label>
                </div>
              </div>
              <div className="mt-8" />
              <TextArea id="nurse-notes" value={apptNotes?.nurse?.notes || ''} onChange={handleNoteChange} />
            </Card>
            <Card className="rounded-t-none border-t-0 last:border-none grid-cols-2" variant="dark">
              <div className="flex flex-row items-center">
                <p className="text-3xl align-middle mr-32">Appointment Complete</p>
                <div className="flex flex-col">
                  <Button className="text-l text-center w-80 ml-24" onClick={handleSaveForLater}>
                    Save and finish later
                  </Button>
                  <div className="mt-8" />
                  <Button className="text-l text-center w-80 ml-24" isDisabled={finishSubmitDisabled} onClick={finishAndSave}>
                    Finish and save notes
                  </Button>
                </div>
              </div>
            </Card>
            <div className="mt-8" />
            <div>
              <QuestionnaireSummary />
              <Card className="rounded-t-none border-t-0 last:border-none grid-cols-2" variant="dark">
                <Button className="text-l text-center w-80 ml-24 float-right" onClick={() => setIsModalOpen(true)} isDisabled={regenerateLoading}>
                  Regenerate report
                </Button>
              </Card>
              <PopUpModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title="Regenerate Report">
                <p className="text-m pr-4 mb-4">
                  Regenerating a report will send a notification to the patient that they have update report details to review. Please confirm this
                  the action you want to complete and why.
                </p>
                <TextArea className="text-charcoal-100" onChange={handleReasonChange} placeholder="Write reason here...">
                  {regenerateReason}
                </TextArea>
                <div className="flex justify-end mt-6">
                  <Button className="bg-lemon-100 text-charcoal-100" isDisabled={regenerateLoading} onClick={handleModalConfirmed}>
                    Regenerate Report
                  </Button>
                  {regenerateError && <div className="text-red-500">Something went wrong releasing results</div>}
                </div>
              </PopUpModal>
            </div>
          </div>
        </div>
      </Page>
    </QuestionnaireProvider>
  );
};

ApptNotePage.fragments = {
  user: gql`
    fragment AppNotePageUserFragment on Patient {
      id
      user {
        firstName
        lastName
        email
        dateOfBirth
        phone
        address {
          address1
          address2
          city
          province
          country
          zip
          province_code
        }
      }
      testKits {
        id
        apptNotes {
          agenda {
            introduced
            verify
            purpose
            consent
            confirmedTeleconferencing
            communicatedNoMedicalHistory
            notes
          }
          audit {
            informationCorrect
            recentContraception
            lowerByMedicalHistory
            notes
          }
          answer {
            messageDelivered
            notes
          }
          actions {
            onContraception
            recommendGpAppt
            showHprDownload
            notes
          }
          nurse {
            concernAboutClient
            technicalIssuesDuringAppt
            notes
          }
        }
        userId
        status
        dateSampleCollected
        product {
          title
        }
        resultsStatus
        results {
          report {
            referenceRange
          }
        }
        createdAt
      }
    }
  `,
};

ApptNotePage.query = gql`
  ${ApptNotePage.fragments.user}

  query AppNotePageGetPatient($input: ByIdInput) {
    patientById(input: $input) {
      ...AppNotePageUserFragment
    }
  }
`;

ApptNotePage.mutation = gql`
  mutation UpdateApptNotes($input: UpdateApptNotesInput!) {
    updateApptNotes(input: $input) {
      id
    }
  }

  mutation RegenerateReport($input: RegenerateReportInput!) {
    regenerateReport(input: $input) {
      id
    }
  }

  mutation FinishApptNotes($input: FinishApptNotesInput!) {
    finishApptNotes(input: $input) {
      id
    }
  }
`;
