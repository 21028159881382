import React, { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import gql from 'graphql-tag';

import { NURSE_ROUTES } from '../nurse.routes';
import { usePatientProfilePageGetPatientQuery } from '@when-fertility/shared/gql/graphql';
import { Icon, Page, URL_PARAMETERS } from '@when-fertility/shared/domain/common';
import { PatientInformation } from './components/patient-information.component';
import { PatientReports } from './components/patient-reports.component';

export const PatientProfilePage = () => {
  const [searchParams] = useSearchParams();
  const userId = useMemo(() => searchParams.get(URL_PARAMETERS.userId), [searchParams]);

  const { data } = usePatientProfilePageGetPatientQuery({
    variables: { input: { id: userId || '' } },
    skip: !userId,
    fetchPolicy: 'cache-and-network',
  });
  const navigate = useNavigate();

  if (!userId) {
    // TODO: do sumfink
    return null;
  }

  return (
    <Page className="pt-20 md:pt-36">
      <div className="text-2xl flex items-center">
        <div className="hover:cursor-pointer" onClick={() => navigate(NURSE_ROUTES.patients)}>
          <Icon icon="arrow-left" height={23} className="mr-4" />
        </div>
        <span>{data?.patientById.user.firstName}&apos;s Patient Profile</span>
      </div>
      <div className="flex flex-col md:flex-row gap-6 mt-6">
        <div className="w-full">
          <PatientInformation user={data?.patientById.user} />
          <div className="mt-6">
            <PatientReports testKits={data?.patientById.testKits} />
          </div>
        </div>
      </div>
    </Page>
  );
};

PatientProfilePage.fragments = {
  user: gql`
    fragment PatientProfilePageUserFragment on Patient {
      id
      user {
        id
        firstName
        lastName
        email
        dateOfBirth
        phone
        address {
          address1
          address2
          city
          province
          country
          zip
          province_code
        }
      }
      testKits {
        id
        apptNotesReportFile {
          s3Path
        }
        apptNotes {
          agenda {
            introduced
            verify
            purpose
            consent
            confirmedTeleconferencing
            communicatedNoMedicalHistory
            notes
          }
          audit {
            informationCorrect
            recentContraception
            lowerByMedicalHistory
            notes
          }
          answer {
            messageDelivered
            notes
          }
          actions {
            onContraception
            recommendGpAppt
            showHprDownload
            notes
          }
          nurse {
            concernAboutClient
            technicalIssuesDuringAppt
            notes
          }
        }
        userId
        accessionNumber
        activationCode
        status
        dateSampleCollected
        product {
          title
        }
        resultsStatus
        results {
          report {
            referenceRange
          }
        }
        createdAt
      }
    }
  `,
};

PatientProfilePage.query = gql`
  ${PatientProfilePage.fragments.user}

  query PatientProfilePageGetPatient($input: ByIdInput) {
    patientById(input: $input) {
      ...PatientProfilePageUserFragment
    }
  }
`;
